import { and, equal, not, or, reads } from '@ember/object/computed';
import RequestStepsChannelFieldsStep from '../components/request/steps/channel-fields-step';
import RequestStepsChannelUploadsStep from '../components/request/steps/channel-uploads-step';
import RequestStepsClientTypeStep from '../components/request/steps/client-type-step';
import RequestStepsClinicianStep from '../components/request/steps/clinician-step';
import RequestStepsDateStep from '../components/request/steps/date-step';
import RequestStepsInformationStep from '../components/request/steps/information-step';
import RequestStepsLocationStep from '../components/request/steps/location-step';
import RequestStepsMessageStep from '../components/request/steps/message-step';
import RequestStepsServiceStep from '../components/request/steps/service-step';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';
import emberO, { computed, setProperties } from '@ember/object';

const CONDENSED_STEPS = ['clinician', 'service', 'location', 'date', 'client-type'];

export const STEP_IDS = {
  SELECT_CLINICIAN: 'select clinician',
  SELECT_SERVICE: 'select service',
  SELECT_LOCATION: 'select location',
  SELECT_DATE: 'select date',
  CLIENT_TYPE: 'client type',
  INTAKE_QUESTIONS: 'intake questions',
  INTAKE_DOCUMENTS: 'intake documents',
  EXTERNAL_DETAILS: 'external details',
  CLIENT_INFORMATION: 'client information',
  REVIEW_AND_CONFIRM: 'review and confirm',
  CONFIRMATION: 'confirmation',
  REQUEST_SENT: 'request sent',
  REQUEST_CANCELLED: 'request cancelled',
  UNAVAILABLE: 'unavailable',
};

@classic
export default class WidgetStepsService extends Service {
  @service currentPractice;
  @service session;
  @service request;
  @service media;
  @service store;

  clinicianHasMonarchProfession = true;

  @reads('currentPractice.featureEnableClientPortalPrescreenerSettings')
  featureEnableClientPortalPrescreenerSettings;

  @equal('currentPractice.clientTypesAllowedToBookAppt.length', 1)
  onlyOneClientTypeAllowedToBookAppt;

  @and('clinicianHasMonarchProfession', 'session.hasPrescreenerFeatures') canShowPrescreenerNav;

  @or('session.isMonarchEmbedded', 'canShowPrescreenerNav') isCondensed;
  @or('session.addMessage', 'canShowPrescreenerNav') canAddMessageStep;

  @not('session.currentClient') isNewClient;
  @and('canAddMessageStep', 'isNewClient') hasMessageStep;
  @and('hasMessageStep', 'currentPractice.showPrescreenerSection') canDisplayPrescreenerSection;

  @computed('hasMessageStep')
  get steps() {
    return [
      emberO.create({
        id: STEP_IDS.SELECT_CLINICIAN,
        isActive: false,
        route: 'clinician',
        title: 'Choose clinician',
        loadingTitle: 'clinicians',
        completedTitle: 'Clinician',
        completedKey: 'clinician',
        menuComponent: RequestStepsClinicianStep,
      }),
      emberO.create({
        id: STEP_IDS.SELECT_SERVICE,
        isActive: false,
        route: 'service',
        title: 'Select service',
        completedTitle: 'Service',
        loadingTitle: 'services',
        completedKey: 'cptCode',
        menuComponent: RequestStepsServiceStep,
      }),
      emberO.create({
        id: STEP_IDS.SELECT_LOCATION,
        isActive: false,
        route: 'location',
        title: 'Select location',
        completedTitle: 'Location',
        loadingTitle: 'locations',
        completedKey: 'office',
        menuComponent: RequestStepsLocationStep,
      }),
      emberO.create({
        id: STEP_IDS.SELECT_DATE,
        isActive: false,
        route: 'date',
        title: 'Select date & time',
        completedTitle: 'Date & time',
        loadingTitle: 'availability',
        completedKey: 'isReserved',
        menuComponent: RequestStepsDateStep,
      }),
      emberO.create({
        id: STEP_IDS.CLIENT_TYPE,
        isActive: false,
        route: 'client-type',
        title: 'Select client',
        completedTitle: 'Client',
        completedKey: 'clientType',
        menuComponent: RequestStepsClientTypeStep,
      }),
      ...((
        this.featureEnableClientPortalPrescreenerSettings
          ? this.canDisplayPrescreenerSection
          : this.hasMessageStep
      )
        ? [
            emberO.create({
              id: STEP_IDS.INTAKE_QUESTIONS,
              isActive: false,
              route: 'message',
              title: 'Message',
              completedTitle: 'Message',
              completedKey: 'hasCompletedMessageStep',
              menuComponent: RequestStepsMessageStep,
            }),
          ]
        : []),
      emberO.create({
        id: STEP_IDS.INTAKE_DOCUMENTS,
        isActive: false,
        route: 'channel-uploads',
        title: 'Intake documents',
        completedTitle: 'Intake documents',
        completedKey: 'channelUploadsComplete',
        menuComponent: RequestStepsChannelUploadsStep,
      }),
      emberO.create({
        id: STEP_IDS.EXTERNAL_DETAILS,
        isActive: false,
        route: 'channel-fields',
        title: 'External details',
        completedTitle: 'External details',
        completedKey: 'channelFields',
        menuComponent: RequestStepsChannelFieldsStep,
      }),
      emberO.create({
        id: STEP_IDS.CLIENT_INFORMATION,
        isActive: false,
        route: 'information.index',
        title: 'Contact information',
        completedTitle: 'Contact information',
        careCoordinatorTitle: "Member's Information",
        careCoordinatorCompletedTitle: "Member's information",
        completedKey: '',
        menuComponent: RequestStepsInformationStep,
      }),
      emberO.create({
        id: STEP_IDS.REVIEW_AND_CONFIRM,
        isActive: false,
        route: 'information.review',
        title: 'Review & confirm',
      }),
      emberO.create({
        id: STEP_IDS.REVIEW_AND_CONFIRM,
        isActive: false,
        route: 'confirmation',
        title: 'Request sent',
        previousStepDisabled: true,
      }),
      emberO.create({
        id: STEP_IDS.REQUEST_CANCELLED,
        isActive: false,
        route: 'cancelled',
        title: 'Request cancelled',
        previousStepDisabled: true,
      }),
      emberO.create({
        id: STEP_IDS.UNAVAILABLE,
        isActive: false,
        route: 'unavailable',
        title: 'Unavailable',
        previousStepDisabled: true,
      }),
    ];
  }

  invalidateActiveStep(controller, routeName) {
    let activeSteps = controller.get('activeSteps');
    if (activeSteps) {
      activeSteps.forEach(step => {
        step.set('isActive', step.route === routeName);
      });
    }
  }

  findActiveSteps(reservation, controller, session) {
    let loadedClinicians = this.store.peekAll('clinician');
    let hasNonMonarchProfessions = loadedClinicians
      .slice()
      .some(({ hasMonarchProfession }) => !hasMonarchProfession);
    let hasSingleClinician = loadedClinicians.length === 1;
    let clinicianHasMonarchProfession = reservation.clinician?.hasMonarchProfession;

    this.set(
      'clinicianHasMonarchProfession',
      clinicianHasMonarchProfession || !hasNonMonarchProfessions
    );
    let activeSteps = [...this.steps];
    if (hasSingleClinician) {
      activeSteps = activeSteps.rejectBy('route', 'clinician');
    }

    if (session.currentClient) {
      activeSteps = activeSteps.reject(
        step => step.route.includes('information') || step.route === 'client-type'
      );
    }

    activeSteps = this.#filterChannelCustomFieldsSteps(
      activeSteps,
      reservation.channel,
      controller
    );

    activeSteps = this.#filterPreselectedFields(activeSteps, controller, reservation);

    activeSteps = this.#mapForCondensedNav(activeSteps);

    activeSteps = this.#orderForReorderedNav(activeSteps);

    return activeSteps;
  }

  #filterChannelCustomFieldsSteps(activeSteps, channel, controller) {
    if (!channel?.hasUploads) {
      activeSteps = activeSteps.reject(step => step.route === 'channel-uploads');
    }
    if (!channel?.showStep(controller)) {
      activeSteps = activeSteps.reject(step => step.route === 'channel-fields');
    }

    return activeSteps;
  }

  #filterPreselectedFields(activeSteps, controller, reservation) {
    if (controller.searchByOffice && reservation.office) {
      activeSteps = activeSteps.rejectBy('route', 'location');
    }

    if (this.onlyOneClientTypeAllowedToBookAppt || controller.clientType) {
      activeSteps = activeSteps.rejectBy('route', 'client-type');
    }

    if (
      (controller.cptCodeId && reservation.cptCode) ||
      reservation.get('channel.widgetSelectChannelService')
    ) {
      activeSteps = activeSteps.rejectBy('route', 'service');
    }

    if (controller.selectedSlot && reservation.startTime) {
      activeSteps = activeSteps.rejectBy('route', 'date');
    }

    return activeSteps;
  }

  #isCondensed({ route }) {
    return CONDENSED_STEPS.includes(route);
  }

  #decorateCondensed({ step }) {
    if (this.#isCondensed(step)) {
      step.setProperties({
        condensedTitle: 'Appointment info',
        isCondensed: true,
        indexLabel: 1,
      });
    }
  }

  #decorateContactInfo({ step }) {
    if (step.route === 'information.index') {
      step.set('condensedTitle', 'Your info');
    }
  }

  #mapForCondensedNav(activeSteps) {
    if (this.isCondensed) {
      activeSteps.forEach((step, index) => {
        let stepOptions = { step };
        this.#decorateCondensed(stepOptions);
        this.#decorateContactInfo(stepOptions);
        step.setProperties({
          canShowCondensedNav: true,
          index,
        });
      });
    }
    return activeSteps;
  }

  #orderForReorderedNav(activeSteps) {
    let { reorderedNavEnabled } = this.session;
    let additionalInfo = activeSteps.findBy('id', 'intake questions');
    let clientInfoStep = activeSteps.findBy('id', 'client information');
    let willReorder = reorderedNavEnabled && clientInfoStep;
    if (additionalInfo) {
      this.#decorateAdditionalInfo(additionalInfo);
      if (willReorder) {
        clientInfoStep.completedKey = 'hasCompletedInformationStep';
        activeSteps.removeObject(additionalInfo);
        activeSteps.insertAt(activeSteps.indexOf(clientInfoStep) + 1, additionalInfo);
      }
    }
    return activeSteps;
  }

  #decorateAdditionalInfo(additionalInfo) {
    let { isMdDown: isMobile } = this.media;
    let title = this.#getAdditionalInfoTitle();
    setProperties(additionalInfo, {
      title,
      completedTitle: title,
      mobileSuffix: isMobile ? '(optional)' : '',
    });
    return additionalInfo;
  }

  #getAdditionalInfoTitle() {
    let { session, canShowPrescreenerNav } = this;
    let { reorderedNavEnabled, addReasonsForVisit } = session;
    if (reorderedNavEnabled) {
      return 'Additional info';
    }
    return addReasonsForVisit || canShowPrescreenerNav ? 'Reason for visit' : 'Message';
  }
}
