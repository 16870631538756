import { registerDestructor } from '@ember/destroyable';
import { waitForNext } from '../utils/waiters';
import Service from '@ember/service';

export default class AlertManagerService extends Service {
  events = ['clearBanner', 'displayBanner', 'displayLocalToast', 'displayToast'];

  #generateError({ event, message, type }) {
    throw new Error(message || `No ${event} event associated with alert type ${type}`);
  }

  #parseEventName({ event = 'display', type }) {
    return `${event}${type.charAt(0).toUpperCase()}${type.slice(1)}`;
  }

  #runEvent({ event, eventName, message, type }) {
    if (this[eventName]) {
      this[eventName](message);
    } else {
      this.#generateError({ event, type });
    }
  }

  bindEventHandler({ context, event, handler }) {
    context._boundEventHandler = handler.bind(context);

    this.on(event, context._boundEventHandler);

    registerDestructor(context, () => {
      context.alertManager.off(event);
    });
  }

  async broadcast(type, message) {
    let eventName = this.#parseEventName({ type });

    await this.waitForBroadcastChannel(eventName);

    this.#runEvent({ eventName, message, type });
  }

  clear(type) {
    let event = 'clear';
    let eventName = this.#parseEventName({ event, type });

    this.#runEvent({ event, eventName, type });
  }

  confirmIsValidEvent(eventName) {
    return this.events.includes(eventName);
  }

  off(eventName) {
    this[eventName] = null;
  }

  on(eventName, handler) {
    if (this.confirmIsValidEvent(eventName)) {
      this[eventName] = handler;
    } else {
      this.#generateError({ message: `Undefined event ${eventName}` });
    }
  }

  async waitForBroadcastChannel(eventName) {
    if (this.confirmIsValidEvent(eventName)) {
      let waiting = !this[eventName];

      while (waiting) {
        await waitForNext();

        waiting = !this[eventName];
      }
    } else {
      this.#generateError({
        message: `Attempting to wait for broadcast channel for event "${eventName}" but it will never resolve!`,
      });
    }
  }
}
