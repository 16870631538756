/* import __COLOCATED_TEMPLATE__ from './questionnaire-form.hbs'; */
import {
  AUDIT,
  AUDITCPLUS,
  DSSB,
  FS,
  GAD7,
  ICG,
  PCL5,
  PEG,
  PHQ15,
  PHQ9,
  SF20,
  SWLS,
  WSAS,
  YBOCS,
} from 'client-portal/models/document-request-questionnaire';
import { action, computed, setProperties } from '@ember/object';
import { bannerError, callbackError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { ensureSafeComponent } from '@embroider/util';
import { filterBy, reads } from '@ember/object/computed';
import { importSync } from '@embroider/macros';
import { modifier } from 'ember-modifier';
import { resetErrors } from 'client-portal/utils/validate-record';
import { service } from '@ember/service';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Super from './autosave-form';
import classic from 'ember-classic-decorator';
import styles from './questionnaire-form.module.scss';
import stylesSuper from './contact-info-form.module.scss';

const MEASUREMENT_FEATURE_FLAGS_MAP = {
  [AUDIT]: 'isMeasurementAuditEnabled',
  [AUDITCPLUS]: 'isMeasurementAuditcplusEnabled',
  [DSSB]: 'isMeasurementDssEnabled',
  [FS]: 'isMeasurementFsEnabled',
  [ICG]: 'isMeasurementIcgEnabled',
  [PCL5]: 'isMeasurementPcl5Enabled',
  [PEG]: 'isMeasurementPegEnabled',
  [PHQ15]: 'isMeasurementPhq15Enabled',
  [SF20]: 'isMeasurementSf20Enabled',
  [SWLS]: 'isMeasurementSwlsEnabled',
  [WSAS]: 'isMeasurementWsasEnabled',
  [YBOCS]: 'isMeasurementYbocsEnabled',
};

@classic
@classNames(styles.component)
export default class SiteDocumentsQuestionnaireForm extends Super {
  @service session;
  @service mixpanel;
  @service currentPractice;
  styles = styles;

  stylesSuper = stylesSuper;
  showModal = false;

  @reads('model') autosaveRecord;
  @reads('model.documentTitle') documentTitle;
  @filterBy(
    'templateQuestionsAndAnswers',
    'question',
    '9. Thoughts that you would be better off dead or of hurting yourself in some way.'
  )
  questionNine;
  @reads('questionNine.firstObject.answer.answers.firstObject.answer_text') questionNineAnswer;

  onModalSetupAndTeardown = modifier(
    () => {
      let footer = document.querySelector('footer');
      footer?.classList?.add('invisible');

      return () => {
        footer?.classList?.remove('invisible');
      };
    },
    { eager: false }
  );

  @computed('model.isReviewing', 'isMeasure', 'questionNineAnswer')
  get canShowModal() {
    return (
      this.isMeasure &&
      this.documentTitle === PHQ9 &&
      this.model.isReviewing &&
      !this.questionNineAnswer?.includes('Not at all')
    );
  }

  @computed('model.{templateQuestionsAndAnswers,userAnswersWithoutScores,isReviewing}', 'isMeasure')
  get templateQuestionsAndAnswers() {
    let { isReviewing, templateQuestionsAndAnswers, userAnswersWithoutScores } = this.model;
    let questionsAndAnswers = templateQuestionsAndAnswers.map(x => {
      let inputModule = importSync(
        `ember-simplepractice/components/shared/questionnaire/${x.normalizedType}-input`
      );
      x.inputComponent = ensureSafeComponent(inputModule.default, this);

      let reviewModule;
      if (['date', 'signature'].includes(x.normalizedType)) {
        reviewModule = importSync(`../../shared/questionnaire/${x.normalizedType}-review`);
      } else {
        reviewModule = importSync(
          `ember-simplepractice/components/shared/questionnaire/${x.normalizedType}-review`
        );
      }

      x.reviewComponent = ensureSafeComponent(reviewModule.default, this);

      return x;
    });

    if (this.isMeasure && isReviewing) {
      return questionsAndAnswers.map(x => {
        let answer = userAnswersWithoutScores.findBy('questionId', x.id);
        return { ...x, answer };
      });
    } else {
      return questionsAndAnswers;
    }
  }

  @action
  additionalClassNames(index) {
    if (!this.isMeasure) {
      return '';
    }

    let isDecoratableFirstQuestion =
      index === 0 && [DSSB, GAD7, PHQ9, PHQ15].includes(this.documentTitle);
    let isDecoratableThirdQuestion =
      index === 2 && [PCL5, WSAS, SWLS, FS].includes(this.documentTitle);
    let isDecoratableSf20Question = [0, 2, 12, 19].includes(index) && this.documentTitle === SF20;
    if (isDecoratableFirstQuestion || isDecoratableThirdQuestion || isDecoratableSf20Question) {
      return 'measurement-title-input';
    }
  }

  @computed(
    'model.mixpanelType',
    ...Object.values(MEASUREMENT_FEATURE_FLAGS_MAP).map(
      featureFlag => `currentPractice.${featureFlag}`
    )
  )
  get isMeasure() {
    if ([GAD7, PHQ9].includes(this.documentTitle)) {
      return this.model.mixpanelType === 'measure';
    }

    let featureFlag = MEASUREMENT_FEATURE_FLAGS_MAP[this.documentTitle];
    return (
      this.model.mixpanelType === 'measure' && featureFlag && this.currentPractice.get(featureFlag)
    );
  }

  @action
  async nextStep() {
    try {
      resetErrors(this.model);
      await this.completeRequestTask.perform(this.model);
      if (!(this.isDestroyed || this.isDestroying)) {
        this.set('autosaveData', {});
      }
    } catch (err) {
      callbackError(err, message => {
        if (message.status !== '422') {
          bannerError(err, { title: `${this.model.documentTitle} could not be submitted` });
        }
        return true;
      });

      await waitForRender();
      this.showValidationError();
    }
  }

  @action
  scrollToTop() {
    if (!this.model.isReview) {
      document.body.scrollTop = 0;
    }
  }

  @action
  editSignature(question) {
    this.setProperties({
      title: 'Edit your signature',
      showSignatureModal: question.id,
    });
  }

  @action
  sign(question) {
    if (this.session.signatoryName) {
      setProperties(question.answer, {
        isDirty: true,
        isSigned: true,
      });
    } else {
      this.setProperties({
        title: 'Create your signature',
        showSignatureModal: question.id,
      });
    }
  }

  @action
  onClose() {
    this.session.currentClient.rollbackAttributes();
    this.session.currentClientAccess.client.rollbackAttributes();
    this.set('showSignatureModal', undefined);
  }
}
